$size: 60px;
* {
    margin: 0;
    padding: 0;
  }
  .wrapper div {
    height: $size;
    width: $size;
    border: 2px solid var(--primary-color);
    position: absolute;
    top: 10%;
    left: 10%;
    animation: 2s linear infinite;
  }
  .wrapper div:nth-child(1) {
    top: 20%;
    left: 20%;
    animation: animate 8s linear infinite;
  }
  .wrapper div:nth-child(2) {
    border-radius: 50px;
    top: 60%;
    left: 80%;
    animation: animate 10s linear infinite;
  }
  .wrapper div:nth-child(3) {
    top: 40%;
    left: 90%;
    animation: animate 3s linear infinite;
  }
  .wrapper div:nth-child(4) {
    top: 50%;
    border-radius: 50px;
    left: 5%;
    animation: animate 6s linear infinite;
  }
  .wrapper div:nth-child(5) {
    border-radius: 50px;
    top: 90%;
    left: 10%;
    animation: animate 9s linear infinite;
  }
  .wrapper div:nth-child(6) {
    border-radius: 50px;
    top: 30%;
    left: 60%;
    animation: animate 5s linear infinite;
  }
  .wrapper div:nth-child(7) {
    top: 70%;
    left: 20%;
    animation: animate 8s linear infinite;
  }
  .wrapper div:nth-child(8) {
    top: 80%;
    left: 70%;
    animation: animate 7.5s linear infinite;
  }
  @keyframes animate {
    0% {
      transform: scale(0) translateY(0) rotate(70deg);
    }
    100% {
      transform: scale(1) translateY(-100px) rotate(360deg);
    }
  }
  @media (max-width: 55em) {
    @keyframes animate {
        0% {
          transform: scale(0) translateY(0) rotate(70deg);
        }
        100% {
          transform: scale(0.7) translateY(-100px) rotate(360deg);
        }
  }
  
}
