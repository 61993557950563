body {
    --primary-color: hsl(93, 90%, 58%);
    --primary-color-darken: hsl(93, 77%, 20%);
    --background-gradient: radial-gradient(ellipse 80% 80% at 50% 5%, var(--primary-color-darken) 0%, #000 100%);
    background: var(--background-gradient);
    color: white;
    width: 100vw;
}


.center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;

    .title {
        width: 50vw;
        text-align: center;
        font-size: 3em;

        h1 {
            margin-bottom: 20px;
        }
    }

    .sub-title {
        width: 50vw;
        text-align: center;
        font-size: 1em;
    }

    .discover-button {
        margin-top: 30px;
        text-align: center;
    }

    .discover {
        background-color: var(--primary-color);
        border: 0;
        border-radius: 25% / 50%;
        padding-inline: 25px;
        padding-block: 15px;
        cursor: pointer;
        transition: 0.1s ease-in;

        &:hover {
            background-color: transparent;
            box-shadow: inset 0px 0px 0px 1.5px var(--primary-color);
            color: white;
        }
    }
}


#profit-sharing {
    height: 100vh;
    width: 100%;
    margin-top: 10vh;
    display: flex;
    align-items: center;

    .left-right-container {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 1rem;
    }

    .left {
        width: 50%;
        overflow-wrap: break-word;
        h1 {
            font-size: 2rem;
            margin-top: 0;
        }

        p {
            width: 80%;
        }
    }

    .right {
        width: 50%;
        overflow-wrap: break-word;
        p {
            width: 80%;
        }
    }
    
}

@media (max-width: 55em) {
    .center-container{
        .title{
            width: 80vw;
        }
        .sub-title{
            width: 80vw;
        }
    }
    .left-right-container {
        flex-direction: column;
        text-align: center;
    }
    .left, .right {
        align-self: center;
        width: 100% !important;
        p{
            width: auto !important;
        }
    }
    

}
