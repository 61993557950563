.licence-center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh;

    .title {
        width: 50vw;
        text-align: center;
        font-size: 3em;

        h1 {
            margin-bottom: 20px;
        }
    }

    .sub-title {
        width: 50vw;
        text-align: center;
        font-size: 1em;
    }

    .discover-button {
        margin-top: 30px;
        text-align: center;
    }

    .discover {
        background-color: var(--primary-color);
        border: 0;
        border-radius: 25% / 50%;
        padding-inline: 25px;
        padding-block: 15px;
        cursor: pointer;
        transition: 0.1s ease-in;

        &:hover {
            background-color: transparent;
            box-shadow: inset 0px 0px 0px 1px var(--primary-color);
            color: white;
        }
    }

}

.licences-tab {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-block: 1rem 3rem;
    justify-content: center;

    p {
        font-size: 0.8rem;
    }

    .licence {
        padding-inline: 1.5rem;
        min-width: 13rem;
        background: linear-gradient(70deg, hsl(0, 0%, 100%, 0.15) 40%, #000);
        border: 1px solid var(--primary-color);
        border-image: linear-gradient(to right, var(--primary-color), darkorchid) 1;



        .avantage {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            text-align: center;
            align-items: center;
            justify-content: center;
            span{
                position: relative;
                text-align: center;
            }
            // span:before{
            //     content: "";
            //     display:inline-block;
            //     width: 20px;
            //     aspect-ratio: 1;
            //     position: absolute;
            //     left: -20px;
            //     top: 1px;
            //     background: url('../sections/images/icons8-multiply-90.png') center/contain;
            //     filter: invert(1) brightness(40%);
            //     background-repeat: no-repeat;
            // }
        }

        .price {
            display: flex;
            align-items: center;
            gap: 1rem;
            color: var(--primary-color);
            margin-bottom: 1rem;
        }

        .more-box {
            margin-block: 20px;
            display: flex;
            justify-content: center;

            .more {
                background-color: var(--primary-color);
                border: 0;
                border-radius: 20% / 50%;
                padding-inline: 25px;
                padding-block: 15px;
                cursor: pointer;
                transition: 0.1s ease-in;

                &:hover {
                    background-color: transparent;
                    box-shadow: inset 0px 0px 0px 1px var(--primary-color);
                    color: white;
                }
            }
        }
    }


}

@media (max-width: 55em) {
    .licences-tab {
        flex-direction: column;
        align-items: center;

        .licence {
            width: 80vw;
        }

    }
}