body {
    min-height: 100vh;
    overflow-x: hidden;
    --primary-color: hsl(93, 90%, 58%);

}

.header {
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 9999;
    transition: 300ms ease-in;
    width: 100%;

    h1 {
        margin: 0;
    }

}

.header-bg {
    background: hsl(0, 0%, 100%, 0.15);
}



.nav-ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    gap: clamp(var(--gap, 1.5rem), 4vw, 5em);

    li {
        text-align: center;
    }

    a {
        text-decoration: none;
        color: #fff;
    }

    .user-management {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
}

.nav-ul[data-visible="true"] {
    transform: translateX(0%);
}

.menu-toggle {
    display: none;
}

@media (max-width: 55em) {
    body {
        font-size: 0.8em;
    }
}

@media (max-width: 55em) {
    .nav-ul {
        background: hsla(0, 0%, 0%, 0.65);
        --gap: 3em;
        position: fixed;
        inset: 0 0 0 30%;
        flex-direction: column;
        padding: min(20vh, 10rem) 2em;
        transform: translateX(100%);
        transition: transform 0.3s ease-out;

    }

    .menu-toggle {
        display: block;
        position: absolute;
        background-color: transparent;
        background: center/contain url("./images/icons8-menu-rounded-100.png");
        background-repeat: no-repeat;
        border: 0;
        width: 2rem;
        aspect-ratio: 1;
        top: 0.5rem;
        right: 1.2rem;
        z-index: 9999;
        filter: brightness(0) invert(1);

        &:hover {
            cursor: pointer;
        }

    }

    .menu-toggle[aria-expanded="true"] {
        background: center/contain url("./images/icons8-multiply-90.png");
    }


}

@supports (backdrop-filter: blur(0.6em)) {
    .header-bg {
        background: hsl(0, 0%, 100%, 0.15);
        backdrop-filter: blur(0.6em);
    }

    @media (max-width: 55em) {
        .nav-ul {
            background: hsl(0, 0%, 100%, 0.15);
            backdrop-filter: blur(0.5em);
        }

        .header-bg {
            background: none;
            backdrop-filter: none;
        }
    }
}

@media (min-width: 55em) {
    .nav-ul {
        padding-block: 1rem;
        padding-inline: clamp(1.5rem, 1vw, 5rem);

    }

}


.logo {
    color: var(--primary-color);
    padding-left: 0.5rem;
    //     animation: logo-rot infinite 5s linear;

    //     @keyframes logo-rot {
    //         from {
    //             transform: rotate(0deg);
    //         }

    //         to {
    //             transform: rotate(360deg);
    //         }
    //     }

}

a {
    transition: 150ms all ease-in;

    &:hover {
        cursor: pointer;
        color: var(--primary-color);

    }

}


.discover {
    text-align: center;
    background-color: var(--primary-color);
    border: 0;
    border-radius: 20% / 50%;
    padding-inline: 25px;
    padding-block: 15px;
    cursor: pointer;
    transition: 0.1s ease-in;

    &:hover {
        background-color: transparent;
        box-shadow: inset 0px 0px 0px 1.5px var(--primary-color);
        color: white;
    }
}